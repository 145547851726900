import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import { Link } from 'react-router-dom';
import CardEventArtChat from './CardEventArtChat';
import { Helmet } from 'react-helmet';

const ArtEvent = ({eventType}) => {
  const { id } = useParams();
  const myUser = useContext(MyUserContext);
  const [artEvent, setArtEvent] = useState(null);
  const [privateAcc, setPrivateAcc] = useState(false);

  // const awaitUpdateArtEvent = () => {
  //   if (eventType === 'recommendation') {
  //     try {
  //       const response = /*await*/ axiosInstance.get(`/recommendations/${id}`);
  //       setArtEvent({
  //         event: response.data.recommendation,
  //         art: response.data.art,
  //         fromUser: response.data.fromUser,
  //         toUser: response.data.toUser
  //       });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //     return;
  //   }

  //   try {
  //     const response = /*await*/ axiosInstance.get(`/likes/${id}`);
  //     setArtEvent({
  //       event: response.data.like,
  //       art: response.data.art
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // TODO: do not explicitly set fields in setArtEvent
  const navigate = useNavigate();
  const updateArtEvent = () => {
    if (eventType === 'recommendation') { // it will live, or not
      axiosInstance
      .get(`/recommendations/${id}`)
      .then((response) => {
        setArtEvent({
          event: response.data.recommendation,
          art: response.data.art,
          fromUser: response.data.fromUser,
          toUser: response.data.toUser,
          chat: response.data.chat,
        });
      })
      .catch((error) => {
        console.error(error);
      });
      return;
    }

    if (eventType === 'artEvent') {
      axiosInstance
      .get(`/artEvents/${id}`)
      .then((response) => {
        setArtEvent({
          event: response.data.event,
          art: response.data.art,
          chat: response.data.chat,
          eventOwnerUserName: response.data.eventOwnerUserName,
          eventOwnerUserAvatarUrl: response.data.eventOwnerUserAvatarUrl
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403 || error.response.status === 401) {
            setPrivateAcc(true);
          } else {
            console.error('Error: ', error.response.data);
          }
        } else {
          console.error(error);
        }
      });
      return;
    }

    // TODO: remove, not used
    axiosInstance
    .get(`/likes/${id}`)
    .then((response) => {
      setArtEvent({
        event: response.data.like,
        art: response.data.art
      });
    })
    .catch((error) => {
      console.error(error);
    });
  };

  // awaitUpdateArtEvent();

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  if (privateAcc === true) {
    return (
      <div class="mt-2 flex justify-center text-center">
        {language === "ru" ? "Вы патаетесь посмотреть добавленное в приватном аккаунте, подпишитесь на пользователя и дождитесь подтверждения" : "You are trying to see a record in the private account, subscribe to the user and wait for their approval"}
      </div>
    )
  }

  if (artEvent === null) {
    updateArtEvent();
    return (
      <div>
      </div>
    )
  }

  // if (eventType === "todo") {
  //   return <div>To be implemented</div>
  // }

  let actualEventType = eventType;
  if (eventType === "artEvent") {
    if (artEvent.event.type === 1) {
      actualEventType = "finished";
    } else if (artEvent.event.type === 2) {
      actualEventType = "todo";
    } else if (artEvent.event.type === 3) {
      actualEventType = "like";
    }
  } // else it's recommendation


  return (
    <div>
      <CardEventArtChat chatState={"shown"} event={artEvent.event} chatInfo={artEvent.chat} userInfo={{name:artEvent.eventOwnerUserName, avatarUrl: artEvent.eventOwnerUserAvatarUrl}} eventType={actualEventType} art={artEvent.art} misc={{fromUser: artEvent.fromUser, toUser: artEvent.toUser}} language={language} />
    </div>
  );
}

export default ArtEvent;