import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import ShareButton from './ShareButton';
import CopyTextButton from './CopyTextButton';
import PersonalRecommendationUsers from './PersonalRecommendationUsers';
import { Link } from 'react-router-dom';

const ArtPage = ({ _art, handleLoginWithGoogle }) => { // oneOf: art, artId
  const navigate = useNavigate();

  const myUser = useContext(MyUserContext);
  const { id } = useParams();

  const [art, setArt] = useState(_art);
  if (_art && art.id !== _art.id) { // it's an ugly hack since the old art is rendered first anyway :(
    setArt(_art);
  }

  const fetchArt = () => {
    axiosInstance
    .get(`/arts/${id}/`)
    .then((response) => {
      setArt(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const handleEventTypeMove = (newEventType) => {
    axiosInstance
    .post(`/artEvents`, { artId: art.id, type: newEventType })
    .then((response) => {
      // setArt((previous) => ({...previous, myEventType: newEventType}));
      setArt(response.data.art);
      // setMyEventType(newEventType); // todo: get rid of separate event type
      //console.log("response.data.event.id: " + response.data.event.id);
      // setEventId(response.data.event.id);
      navigate("/artEvents/"+response.data.art.myEventId+"?tagsInitFocus=true");
    })
    .catch((error) => {
      console.error(error);
    });
  };

  if (art === undefined || art == null) {
    fetchArt();
    return (
      <>
      </>
    );
  }

  const getIconFileForLink = (artLink) => {
    const hostnameParts = new URL(artLink).hostname.split('.');
    const secondLevelDomain = hostnameParts[1];
    let iconFile = "/wiki_icon.png"; // todo: remove, won't be needed soon
    if (secondLevelDomain === 'steampowered') {
      iconFile = "/steam_icon.png";
    } else if (secondLevelDomain == 'imdb') {
      iconFile = "/imdb_icon.png";
    } else if (secondLevelDomain == 'apple') {
      iconFile = "/applebooks_icon.png";
    } else if (secondLevelDomain == 'rottentomatoes') {
      iconFile = "/rottentomatoes_icon.png";
    } else if (secondLevelDomain == 'kinopoisk') {
      iconFile = "/kinopoisk_icon.png";
    } else if (secondLevelDomain == 'goodreads') {
      iconFile = "/goodreads_icon.png";
    } else if (secondLevelDomain == 'amazon') {
      iconFile = "/amazon_icon.png";
    }
    return iconFile;
  }



  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  return (
    <div class="divide-y container mx-auto mt-4 md:mt-8 px-4 md:px-8 w-full lg:w-1/2">
      <div>
        <div class="flex justify-center">
          <img class="max-h-96" src={art.thumbnailUrl} />
        </div>
        <div class="text-3xl flex justify-center text-center">
          <b>{localizeArt(art, language).name + (art.year ? " (" + art.year + ")" : "") } </b>
        </div>
        <div class="flex justify-center text-center space-x-2">
          { // Links to platforms
            [localizeArt(art, language).wikiLink, ...(art.otherPlatformsLinks || [])].map(
              (link, index) => {
                return (
                  <a href={link} target="_blank" rel="noopener noreferrer" className="flex items-center">
                    <img
                      src={getIconFileForLink(link)}
                      alt="Source"
                      className="max-w-24 max-h-10 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
                    />
                  </a>
                )
              }
            )
          }
        </div>
        <div class="flex justify-center text-center">
          {localizeArt(art, language).description}
        </div>
        <div class="flex justify-center">
          {localizeArt(art, language).videoGameDeveloper}
        </div>
      </div>

      <div class="mt-4 mb-4 text-xl">
        <div>
          {
          art.otherPeopleEvents ?
            <>
              <div class="mt-4 flex justify-center">
                {language === "ru" ? "В библиотеке других людей:" : "In the libraries of other people:"}
              </div>
              <div class="flex justify-center">
                <div class="grid grid-cols-5 gap-1">
                  {art.otherPeopleEvents.map(
                    (ev, index) => {
                      return (
                        <div>
                          <Link to={`/artEvents/${ev.artEventId}`}>
                            <img
                              src={ev.userAvatarUrl}
                              class={"w-12 h-12 rounded-full"}
                            />
                          </Link>                        
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            </>
          :
            <div class="mt-4 flex justify-center text-center">
              {language === "ru" ? "Другие пользователи пока не добавили себе" : "Other people haven't added it yet"}
            </div>
          }
        </div>

        {
        myUser
        ?
          <div class="mt-4">
            {
            !(art.myEventType === "like" || art.myEventType === "todo" || art.myEventType === "finished") &&
              <div class="flex justify-center items-center space-x-3">
                <div class="cursor-pointer space-x-1">
                  <div class="text-blue-500 hover:text-blue-800 cursor-pointer"
                    onClick={()=>{
                      handleEventTypeMove("todo");
                    }} 
                  >
                    {language === "ru" ? "Добавить и открыть в моей библиотеке" : "Add and open in my library"}
                  </div>
                </div>
              </div>
            }

            {
            (art.myEventType === "like" || art.myEventType === "todo" || art.myEventType === "finished") &&
            <div class="flex justify-center text-center">
              <Link class="cursor-pointer text-blue-500 hover:text-blue-800" to={"/artEvents/"+art.myEventId}>
                {language === "ru" ? "Перейти к произведению в моей библиотеке" : "Go to the art in my library"}
              </Link>
            </div>
            }
          </div>
        :
          <div class="mt-4 flex justify-center text-center items-center">
            {
              language === "ru" ?
              <div>
                <div class="flex justify-center text-center mb-4 font-medium">
                  Залогиньтесь и добавьте в свою библиотеку
                </div>
                <div class="flex justify-center">
                  <div onClick={handleLoginWithGoogle} class="w-4/5 md:w-2/3 flex px-4 items-center hover:cursor-pointer border border-black rounded-full text-lg">
                    <img src="/google-login-image_no_text.png" />
                    Войти через Google
                  </div>
                </div>
              </div>                
              :
              <div>
                <div class="flex justify-center text-center mb-4 font-medium">
                  Log in and add to your library
                </div>
                <div class="flex justify-center">
                  <img class="w-4/5 md:w-1/3" onClick={handleLoginWithGoogle} src="/google-login-image.png" />
                </div>
              </div>
            }
          </div>
        }
      </div>

      {
      myUser &&
      <div class="mt-4 mb-8 text-xl">
        <PersonalRecommendationUsers art={art} />
      </div>
      }
    </div>
  );
}

export default ArtPage;