import { useState } from 'react';
import Modal from './Modal.js';

const ExpandableImage = ({ src, onLoad, small = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  let sizeStyle = " w-12 h-12";
  if (small === true) {
    sizeStyle = " w-8 h-8";
  }

  return (
    <div className="relative">
      <img
        src={src}
        onLoad={onLoad}
        alt="Image"
        class={"object-cover flex-none rounded-full cursor-pointer" + sizeStyle}
        onClick={openModal}
      />
      {isModalOpen && <Modal imageUrl={src} closeModal={closeModal} />}
    </div>
  );
};

export default ExpandableImage;
