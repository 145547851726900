export const localizeKey = (key, lang) => {
  const mappings = {
    'homePage': {
      'en': 'Home page',
      'ru': 'Главная страница'
    },
    'orSearchForBooksHere': {
      'en': 'Or, search for books here:',
      'ru': 'Или можете поискать книгу здесь (на русском и английском):'
    },
    'backToMyPage': {
      'en': '⬅️ Back to users',
      'ru': '⬅️ Назад к пользователям'
    },
    'putArtIntoCategories': {
      'en': 'Put art into one of the categories',
      'ru': 'Добавьте произведение в одну из категорий'
    },
    'artIsEitherMovieGameBook': {
      'en': 'Art is either movie, videogame or book',
      'ru': 'Произведение может быть фильмом, игрой или книгой'
    },
    'copyLinkFromPlatforms': {
      'en': 'Copy a link from one of the platforms',
      'ru': 'Скопируйте ссылку с одной из платформ'
    },
    'wikiMustHaveEnglishVersion': {
      'en': 'for books, provide books.apple.com link to a book, or use search inside the app',
      'ru': 'для добавления книги введите ссылку на на books.apple.com или используйте поиск внутри приложения'
    },
    'othersReaction': {
      'en': 'added to their library too',
      'ru': 'также добавили в свою библиотеку'
    },
    'myReaction': {
      'en': 'Add to mine',
      'ru': 'Добавить к себе'
    },
    'addedToTheirLib': {
      'en': 'added to library',
      'ru': 'добавил(а) в библиотеку'
    },
    'removeFromLib': {
      'en': 'Remove',
      'ru': 'Удалить'
    },
    'like_did': {
      'en': 'liked',
      'ru': 'лайкнул(а)'
    },
    'todo_did': {
      'en': 'added to todo',
      'ru': 'запланировал(а)'
    },
    'finished_did': {
      'en': 'finished',
      'ru': 'ознакомился(ась)'
    },
    'chooseCategory': {
      'en': 'Choose category',
      'ru': 'Выберите категорию'
    },
    'collapsibleHideCap': {
      'en': 'Hide',
      'ru': 'Скрыть'
    },
    'collapsibleShowCap': {
      'en': 'Show',
      'ru': 'Показать'
    },
    'myLikes': {
      'en': "My likes",
      'ru': "Мне понравилось"
    },
    'likes': {
      'en': "Favorites",
      'ru': "Любимое"
    },
    'todos': {
      'en': "Todos",
      'ru': "Планы"
    },
    'finisheds': {
      'en': "Checked out",
      'ru': "Ознакомлен(а)"
    },
    'publicTodos': {
      'en': "Public todos",
      'ru': "Опубликованные планы"
    },
    'like': {
      'en': "Like",
      'ru': "Понравилось"
    },
    'links': {
      'en': "Links",
      'ru': "Ссылки"
    },
    'liked': {
      'en': "liked",
      'ru': "оценил"
    },
    'something': {
      'en': "something",
      'ru': "что-то"
    },
    'recommendedToYou': {
      'en': "recommended to you",
      'ru': "рекомендовал вам"
    },
    'planned': {
      'en': "planned",
      'ru': "запланировал(а)"
    },
    'home': {
      'en': "Liked",
      'ru': "Понравилось"
    },
    'myTodos': {
      'en': "My todos",
      'ru': "Оценю позже"
    },
    'click_cap': {
      'en': "Click",
      'ru': "Нажми"
    },
    'toGetNotifications': {
      'en': "to get notifications",
      'ru': ", чтобы получать нотификации"
    },
    'copySecretToken_cap': {
      'en': "Copy secret token",
      'ru': "Скопируйте секретный токен"
    },
    'sendIt_cap': {
      'en': "Send it",
      'ru': "Отправьте его"
    },
    'here_direction': {
      'en': "here",
      'ru': "сюда"
    },
    'setUserName': {
      'en': "Set user name",
      'ru': "Поменять юзернейм"
    },
    'recommendedByMe': {
      'en': "By me",
      'ru': "От меня"
    },
    'recommendedToMe': {
      'en': "To me",
      'ru': "Мне"
    },
    'toWhomRecommended': {
      'en': "To whom",
      'ru': "Кому"
    },
    'byWhomRecommended': {
      'en': "By whom",
      'ru': "От кого"
    },
    'users': {
      'en': "Users",
      'ru': "Пользователи"
    },
    'addToMyList': {
      'en': "In my library",
      'ru': "В моей библиотеке"
    },
    'addTodo': {
      'en': "Add todo",
      'ru': 'Оценю позже'
    },
    'addFinished': {
      'en': "Checked out",
      'ru': "Ознакомился"
    },
    'settings': {
      'en': "Settings",
      'ru': "Настройки"
    },
    'enterWikiLink': {
      'en': "Wiki link",
      'ru': "Ссылка на вики"
    },
    'wikiLink': {
      'en': "Wikipedia link",
      'ru': "Ссылка на вики"
    },
    'likeTime': {
      'en': "Time of like",
      'ru': "Дата добавления"
    },
    'todoTime': {
      'en': "Time of todo",
      'ru': "Дата добавления"
    },
    'recommendationTime': {
      'en': "Recommendation time",
      'ru': "Дата добавления"
    },
    'recommend': {
      'en': "Recommend personally",
      'ru': "Рекомендовать персонально"
    },
    'personalRecommendations': {
      'en': "Personal recommendations",
      'ru': "Персональные рекомендации"
    },
    'pinsLimitReached': {
      'en': "Pins limit reached (max 5)",
      'ru': "Максимум 5 закрепленных"
    },
    'fromUser': {
      'en': "From user",
      'ru': "От кого"
    },
    'toUser': {
      'en': "To user",
      'ru': "Кому"
    },    
    'wikiLinkHint': {
      'en': "Link to a movie, a game or a book",
      'ru': "Ссылка на фильм, игру или книгу"
    },
    'likesHeader': {
      'en': "Share what you liked. Other people will see it.",
      'ru': "Поделитесь тем, что вам понравилось. Другие люди увидят это."
    },
    'recommendedToMeHeader': {
      'en': "What other people recommended to you privately.",
      'ru': "То, что рекомендовали лично вам."
    },
    'recommendedByMeHeader': {
      'en': "What you recommended to people privately.",
      'ru': "То, что вы рекомендовали лично."
    },
    'recommendationPostSuccess': {
      'en': 'Successfully posted recommendation',
      'ru': 'Рекомендация успешно отправлена'
    },
    'timeOf': {
      'en': 'Time of',
      'ru': 'Время'
    },
    'of_like': {
      'en': 'like',
      'ru': 'лайка'
    },
    'of_recommendation': {
      'en': 'recommendation',
      'ru': 'рекомендации'
    },
    'of_todo': {
      'en': 'todo',
      'ru': 'добавления в планы'
    },
    'of_finished': {
      'en': 'check out',
      'ru': 'ознакомления'
    },
    'finishedNavBar': {
      'en': "Checked out",
      'ru': "Ознакомился(лась)"
    },
    'finished': {
      'en': "checked out",
      'ru': "ознакомился(лась)"
    },
    'share': {
      'en': 'Share',
      'ru': 'Поделиться'
    },
    'copy': {
      'en': 'Copy',
      'ru': 'Скопировать'
    },
    'copyLink': {
      'en': 'Copy link',
      'ru': 'Скопировать ссылку'
    },
    'messagedIn': {
      'en': "messaged in",
      'ru': "написал(а) в"
    },
    'chat': {
      'en': 'chat',
      'ru': 'чат'
    },
    'messages': {
      'en': 'messages',
      'ru': 'сообщений'
    },
    'chat_in': {
      'en': "a chat",
      'ru': "чате"
    },
    'shareEvent': {
      'en': "Share event",
      'ru': "Поделиться событием"
    },
    'publishMyPlan': {
      'en': "Publish my plan",
      'ru': "Опубликовать план"
    },
    'unpublishMyPlan': {
      'en': "Unpublish my plan",
      'ru': "Убрать из опубликованных планов"
    },
    'shareRecomText': {
      'en': 'Here\'s recommendedation for you',
      'ru': 'Лови рекомендацию'
    },
    'shareRecomNotMiss': {
      'en': 'Share manually with your friend so they don\'t miss it',
      'ru': 'Поделитесь вручную этой рекомендацией, чтобы ее не пропустили'
    },
    'recommendationForYou': {
      'en': 'Here\'s recommendedation for you',
      'ru': 'Рекомендация для вас'
    },
    'copiedToClipboard_lower': {
      'en': 'copied to clipboard',
      'ru': 'скопировано'
    },
    'eventOwner': {
      'en': "Event owner",
      'ru': "Автор события"
    },
    'subscribe': {
      'en': "Subscribe",
      'ru': "Подписаться"
    },
    'unsubscribe': {
      'en': "Unsubscribe",
      'ru': "Отписаться"
    },
    'uploadAvatar': {
      'en': "Upload avatar",
      'ru': "Загрузить аватар"
    },
    'typeYourMessageHere': {
      'en': "Type your message here...",
      'ru': "Написать сообщение..."
    },
    'youHaveUnreadMessagesInChat': {
      'en': "You have unread messages in chat",
      'ru': "У вас есть непрочитанные сообщения в чате"
    },
    'follow': {
      'en': "Follow",
      'ru': "Подписаться"
    },
    'requested': {
      'en': "Requested",
      'ru': "Запрошено"
    },
    'approved': {
      'en': "Following",
      'ru': "Подписаны"
    },
    'unfollow': {
      'en': "Unfollow",
      'ru': "Отписаться"
    },
    // Add more mappings as needed
  };

  return mappings[key][lang];
}

export const localizeArt = (art, lang) => {
  if (lang === 'ru') {
    return {
      'name': art.ruName !== '' ? art.ruName : art.name,
      'description': art.ruDescription !== '' ? art.ruDescription : art.description,
      'wikiLink': art.ruWikiArticleLink !== '' ? decodeURIComponent(art.ruWikiArticleLink) : art.wikiArticleLink
    }
  }

  return {
    'name': art.name,
    'description': art.description,
    'wikiLink': art.wikiArticleLink
  }
}