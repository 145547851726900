import React, { useState, useContext } from 'react';
import MyUserContext from './../myUserContext.js';

const Tabs = ({onTabChange, counters, initTab}) => {
  const myUser = useContext(MyUserContext);

  const [activeTab, setActiveTab] = useState(initTab);
  // alert("tabs active tab '" + activeTab + "'");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  return (
    <div className="flex justify-center items-center">
      <div className="tabs flex space-x-4 bg-white p-4 rounded">
        <button
          className={`tab py-2 px-4 rounded transition duration-300 ${
            activeTab === 'movie' ? 'bg-blue-500 text-white' : 'bg-white text-black'
          }`}
          onClick={() => {
            onTabChange('movie');
            handleTabClick('movie');            
          }}
        >
          {language === "ru" ? "Фильмы" : "Movies"}
          {counters["movie"] !== null ? " ("+counters["movie"].length + ")" : "..."}
        </button>
        <button
          className={`tab py-2 px-4 rounded transition duration-300 ${
            activeTab === 'book' ? 'bg-blue-500 text-white' : 'bg-white text-black'
          }`}
          onClick={() => {
            onTabChange('book');
            handleTabClick('book');            
          }}
        >
          {language === "ru" ? "Книги" : "Books"}
          {counters["book"] !== null ? " ("+counters["book"].length + ")" : "..."}
        </button>
        <button
          className={`tab py-2 px-4 rounded transition duration-300 ${
            activeTab === 'videoGame' ? 'bg-blue-500 text-white' : 'bg-white text-black'
          }`}
          onClick={() => {
            onTabChange('videoGame');
            handleTabClick('videoGame');
          }}
        >
          {language === "ru" ? "Игры" : "Video games"}
          {counters["videoGame"] != null ? " ("+counters["videoGame"].length + ")" : "..."}
        </button>
      </div>
    </div>
  );
};

export default Tabs;
