import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import TextUserPair from './TextUserPair.js';
import ExpandableImage from './ExpandableImage';

const UserRecommendPairButton = ({user, art, language}) => {
  const [success, setSuccess] = useState(false);

  const handleRecommend = (userId, artId) => {
    axiosInstance
    .post('/postRecommendation', { toUserId: userId, artId: art.id })
    .then((response) => {
      setSuccess(true);
      //setSuccessText(localizeKey('recommendationPostSuccess', language));
    })
    .catch((error) => {
      console.error(error);
    });
  }

  return (
    <div class="flex justify-between justify-center items-center">
      <div class="flex items-center">
        <ExpandableImage src={user.avatarUrl} />&nbsp;      
        {user.userName}
      </div>
      {
      success === false ?
      <div class="text-blue-500 hover:text-blue-800 cursor-pointer" onClick={()=>{handleRecommend(user.id, art.id);}}>
        {language === "ru" ? "Рекомендовать" : "Recommend"}
      </div> :
      <div>
        ✅
      </div>
      }
      
    </div>
  );
};

const PersonalRecommendationUsers = ({art}) => {
  const myUser = useContext(MyUserContext);

  const [users, setUsers] = useState([]);
  const updateUsers = () => {
    axiosInstance
    .get(`/getUsers`)
    .then((response) => {
      response.data.shift(); // get rid of own record
      setUsers(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  return (
    <div class="mt-4">
      {
      <div class="flex justify-center text-blue-500 hover:text-blue-800 cursor-pointer" onClick={()=>{ 
        if (users.length === 0) {
          updateUsers();
        } else {
          setUsers([]);
        }
      }}>
      {
        users.length === 0 ? 
          (language === "ru" ? "Рекомендовать кому-нибудь персонально" : "Recommend to someone personally")
        :
          (language === "ru" ? "Скрыть" : "Hide")
      }
      </div>
      }

      <div class="container mx-auto mt-2 md:mt-4 flex justify-center items-center flex-col w-full lg:w-2/3">
        <ul role="list" className="container mx-auto divide-y divide-gray-100 px-2">
          {users.map((user) => (
            <UserRecommendPairButton user={user} art={art} language={language}/>          
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PersonalRecommendationUsers;
