import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import CardEventArtChat from './CardEventArtChat';
import { Link } from 'react-router-dom';

const RecommendationEvents = ({ params }) => {
  const myUser = useContext(MyUserContext);

  const [recommendationEvents, setRecommendationEvents] = useState(null);

  const fetchRecommendationEvents = () => {
    const queryParams = params.fromMe === true ? "?fromMe=true" : "";
    axiosInstance
    .get(`/getRecommendations`+queryParams)
    .then((response) => {
      setRecommendationEvents(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  if (recommendationEvents === null) {
    fetchRecommendationEvents();
    return (
      <div></div>
    )
  }

  const language = myUser !== null ? myUser.myUserSettings.lang : 'ru';

  const classFromMe = (params.fromMe === true ? "underline" : "");
  const classToMe = (params.fromMe !== true ? "underline" : "");

  return (
    <div class="container mx-auto px-4 md:px-8 w-full lg:w-1/2">
      <div class="flex my-6 justify-center">
        <Link class="text-blue-500 hover:text-blue-800" to="/users">
          {localizeKey('backToMyPage', language)}
        </Link>
      </div>      
      <div class="font-semibold flex justify-center">
        {localizeKey('personalRecommendations', language)}
      </div>
      <div class="flex justify-center space-x-4">        
        <div class={classToMe}>
          <Link class="text-blue-500 hover:text-blue-800" to="/recommendations/toMe">
            {localizeKey('recommendedToMe', language)}
          </Link>
        </div>
        <div class={classFromMe}>
          <Link class="text-blue-500 hover:text-blue-800" to="/recommendations/fromMe">
            {localizeKey('recommendedByMe', language)}
          </Link>
        </div>
      </div>

      <ul className="container mx-auto divide-y divide-gray-100">
        {recommendationEvents.map((recom, index) => (
          <li class="container mx-auto">
            <CardEventArtChat event={recom.recommendation} chatInfo={recom.chat} eventType="recommendation" art={recom.art} language={language} misc={{'fromMe': params.fromMe, 'fromUser': recom.fromUser, 'toUser': recom.toUser }} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default RecommendationEvents;