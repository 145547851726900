import React, { useContext, useState } from 'react';
import {localizeKey, localizeArt} from './../localize.js';
import MyUserContext from './../myUserContext.js';

function ShareButton({ url, text, overrideButtonText }) {
  const myUser = useContext(MyUserContext);

  const initialButtonText = () => {
    const language = myUser ? myUser.myUserSettings.lang : 'ru';
    return overrideButtonText ? overrideButtonText : localizeKey('share', language);
  }

  const [buttonText, setButtonText] = useState( initialButtonText() );

  const handleClick = async () => {
    if (navigator.share === undefined) { // non-mobile
      navigator.clipboard.writeText(url);
      const language = myUser ? myUser.myUserSettings.lang : 'ru';
      setButtonText((language === "ru" ? "Ссылка скопирована" : "Link copied") + " ✔");
      setTimeout(() => {
        setButtonText(initialButtonText());
      }, 900);
      return
    }

    try {
      await navigator.share({
        text: text,
        url: url
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return (
    <div class="font-bold py-1 px-2 inline-block rounded text-blue-600 hover:text-blue-900 hover:text-blue-900 border-2 border-blue-600 hover:border-blue-900 hover:cursor-pointer" onClick={handleClick}>
      {
        buttonText
      }
    </div>
  );
}

export default ShareButton;