import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';

const Notifications = ( {unreadCountCallback} ) => {
  const myUser = useContext(MyUserContext);

  const [notifications, setNotifications] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);

  const fetchNotifications = async () => {
    try {
      const response = await axiosInstance.get('/notifications');
      setNotifications(response.data);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const markAsRead = async (id) => {
    try {
      await axiosInstance.post(`/notifications/${id}/markAsRead`);
      const newUnreadCount = Math.max(0, unreadCount - 1);
      unreadCountCallback(newUnreadCount);
      setUnreadCount(newUnreadCount);
      fetchNotifications();
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const response = await axiosInstance.get('/notifications/unreadCount');
        setUnreadCount(response.data.unreadCount);
        unreadCountCallback(response.data.unreadCount)
      } catch (error) {
        console.error('Failed to fetch unread notification count:', error);
      }
    };

    fetchUnreadCount();
  }, []);

  const parseStringToBold = (text) => {
    const parts = text.split(/(\*[^*]+\*)/); // Split the string by *...* pattern

    return parts.map((part, index) => {
      if (part.startsWith('*') && part.endsWith('*')) {
        return <strong key={index}>{part.slice(1, -1)}</strong>;
      } else {
        return part;
      }
    });
  };

  const NotificationCard = ({notification}) => {
    const navigate = useNavigate();

    // const eventType = notification.notification.eventType;
    // let link = "/artEvents/"+notification.notification.eventEntityId;

    // let ntfText = "";
    // let textPayload = notification.notification.textPayload;
    // let verbLocalizeKey = "";
    // let lastWordLocalizeKey = "";
    // if (eventType === "like") {
    //   verbLocalizeKey = "liked";
    //   lastWordLocalizeKey = "something";
    // } else if (eventType === "recommendation") {
    //   link = "/recommendations/"+notification.notification.eventEntityId;
    //   verbLocalizeKey = "recommendedToYou";
    // } else if (eventType === "finished") {
    //   // link = "/artEvents/"+notification.notification.eventEntityId;
    //   verbLocalizeKey = "finished";      
    //   lastWordLocalizeKey = "something";
    // } else if (eventType === "todo") {
    //   // link = "/artEvents/"+notification.notification.eventEntityId;
    //   verbLocalizeKey = "planned";
    //   lastWordLocalizeKey = "something";
    // } else if (eventType === "like:message" || eventType === "todo:message" || eventType === "finished:message") {      
    //   verbLocalizeKey = "messagedIn";
    //   lastWordLocalizeKey = "chat_in";
    // } else if (eventType === "recommendation:message") {
    //   link = "/recommendations/"+notification.notification.eventEntityId;
    //   verbLocalizeKey = "messagedIn";
    //   lastWordLocalizeKey = "chat_in";
    // } else {
    //   ntfText = textPayload; // just take text from backend
    // }
    // if (textPayload !== "" && ntfText === textPayload) { // ugly way of checking raw backend-text ntf
    //   // Unknown ntf type.
    //   // Do nothing.
    // } else {
    //   // Known ntf type.
    //   ntfText = notification.fromUser.userName + " " + localizeKey(verbLocalizeKey, language) + (lastWordLocalizeKey !== "" ? " " + localizeKey(lastWordLocalizeKey, language) : "");
    //   ntfText = ntfText + (textPayload !== "" ? ": \"" + textPayload + "\"" : "");
    //   if (verbLocalizeKey === "messagedIn") { // it's a workaround, refactor here and above
    //     ntfText = localizeKey('youHaveUnreadMessagesInChat', language);
    //   }
    // }

    const ntfText = notification.title;
    const link = notification.linkToEvent;


    let twClass = notification.notification.read === false ? "opacity-100" : "opacity-20";
    twClass = "hover:cursor-pointer" + " " + twClass; 

    return (
      <div class={twClass} onClick={
        () => {
          markAsRead(notification.notification.id);
          navigate(link);
        }
      }>
        <div>
          <div class="flex">
            <img class="max-w-12" src={notification.artImageUrl} />
            <div class="ml-2 flex items-center">
              <div class="mr-1 mb-1">
                <img class="max-w-7 rounded-full" src={notification.initiatingUserAvatarUrl}/>
              </div>
              <div class="text-sm leading-none">
                {parseStringToBold("*" + notification.initiatingUserName + "* " + ntfText)}
              </div>
            </div>
          </div>
          <div class="-mb-2">
            {new Date(notification.createdTime).toLocaleDateString(language, { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
          </div>
        </div>
      </div>
    )
  }

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  return (
    <div class="container mx-auto px-4 w-full lg:w-1/2">
      {
      notifications && notifications.length == 0 &&
      <div class="flex mt-2 md:mt-4 justify-center items-center text-center">
        {
        (language === "ru") ?
        <>
          У вас пока нет уведомлений.<br/>
          Подписывайтесь на других пользователей, чтобы получать их.
        </>
        :
        <>
          No notifications yet.<br/>
          Subscribe to other users to receive notifications.
        </>
        }
      </div>
      }

      {
      notifications && notifications.length > 0 &&
      <ul className="container mx-auto mt-5">
        {notifications.map((notification) => (
          <li key={notification.notification.id} style={{ marginBottom: '5px', padding: '10px', border: '1px solid #ccc', 'border-radius': '7px' }}>
              <NotificationCard notification={notification}/>
          </li>
        ))}
      </ul>
      }
    </div>
  );
};

export default Notifications;
