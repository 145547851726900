import React, { useState, useEffect, useRef } from "react";
import axiosInstance from './../axiosInstance.js';

const TagsInput = ({ initialTags = [], onTagsChange, editable, language, initFocus = false }) => {
  const [tags, setTags] = useState(initialTags); // Initialize with array of strings
  const [searchResults, setSearchResults] = useState([]); // For auto-complete
  const [searchQuery, setSearchQuery] = useState(""); // Input field query
  const [isDropdownVisible, setIsDropdownVisible] = useState(false); // Manage dropdown visibility

  const inputRef = useRef(null); // Ref for the input element
  const dropdownRef = useRef(null); // Ref for the dropdown menu

  const [initFocusRelevant, setInitFocusRelevant] = useState(true);
  useEffect(() => {
    if (inputRef.current && initFocus && initFocusRelevant) {
      inputRef.current.focus();
      setInitFocusRelevant(false); // is it really needed here?
    }
  });

  const addTag = (tag) => {
    let updatedTags = [...tags];

    if (isGlobalManualTag(tag)) {
      // Remove previous global manual tag.
      updatedTags = updatedTags.filter(tagName => !isGlobalManualTag(tagName));
    }

    if (updatedTags.length === 5){
      let txt = "Maximum 5 tags";
      if (language === "ru") {
        txt = "Максимум 5 тэгов";
      }
      alert(txt);
      return;
    }

    if (!updatedTags.includes(tag)) { // Check if tag is already included
      updatedTags = [...updatedTags, tag];
      setTags(updatedTags);
      onTagsChange(updatedTags); // Notify the parent of updated tags
    }
  };

  const removeTag = (tag) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
    onTagsChange(updatedTags); // Notify the parent of updated tags
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (query.length > 0 || true) {
      try {
        const response = await axiosInstance.get(`/relevant/tags/search?query=${query}`);
        setSearchResults(response.data.map(item => item.name));
        setIsDropdownVisible(true); // Show the dropdown when there are results
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      setSearchResults([]); // Clear search results if input is empty
      setIsDropdownVisible(false); // Hide the dropdown if there are no results
    }
  };

  const handleAddTag = (tag) => {
    addTag(tag);
    setSearchQuery(""); // Clear the search input after adding
    setSearchResults([]); // Clear the search results after adding
    setIsDropdownVisible(false); // Hide the dropdown after adding
  };

  const handleInputChange = (e) => {    
    handleSearch(e.target.value);
  };

  const handleDropdownItemClick = (tag) => {
    handleAddTag(tag);
    setInitFocusRelevant(false);
  };

  const handleClickOutside = (event) => {
    // Hide the dropdown if clicking outside
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the component
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // It should be handled in backend.
  const isGlobalManualTag = (name) => {
    if (name === "like" || name === "todo" || name === "finished") {
      return true;
    }
    return false;
  }
  const isGlobalAutomaticTag = (name) => {
    if (name === "book" || name === "movie" || name === "game") {
      return true;
    }
    return false;
  }
  const tagColor = (name) => {
    if (isGlobalManualTag(name)) {      
      if (name === "like") {
        return "bg-red-500/80 text-gray-700";
      } else if (name === "todo") {
        return "bg-blue-200/50 text-gray-700";
      } else { // name === "finished"
        return "bg-yellow-200/50 text-gray-700";
      }
    } else if (isGlobalAutomaticTag(name)) {
      return "bg-green-200/50";
    } else {
      return "bg-gray-200";
    }
  }

  const tagName = (name) => {
    const mapping = {
      'en': {
        'like': 'favorite',
        'todo': 'todo',
        'finished': 'finished'
      },
      'ru': {
        'like': 'любимое',
        'todo': 'планы',
        'finished': 'ознакомлен(а)',

        'book': 'книга',
        'movie': 'фильм',
        'game': 'игра'
      }
    }
    const val = mapping[language][name];
    if (val === undefined) {
      return name;
    } else {
      return val;
    }
  }

  return (
    <div className="relative px-2 md:px-4 py-2">
      {/* Display the list of added tags */}
      <div className="flex flex-wrap gap-1 mb-1">
        {tags.map((tag, index) => (
          <div
            key={index}
            className={"cursor-pointer px-2 py-1 rounded text-xs md:text-base " + tagColor(tag)}
          >
            {"#"+tagName(tag)}

            {
            (!isGlobalAutomaticTag(tag) && !isGlobalManualTag(tag) && editable) &&
            <button
              className="ml-2 text-xs lg:text-sm text-gray-500"
              onClick={() => removeTag(tag)}
            >
              &times;
            </button>
            }
          </div>
        ))}
      </div>

      {/* Search Results Dropdown */}
      {isDropdownVisible && searchResults.length > 0 && (
        <div
          ref={dropdownRef}
          className="absolute top-full left-0 right-0 bg-gray-100 border border-gray-300 rounded mb-2 max-h-40 overflow-y-auto z-10"
        >
          {searchResults.map((tag, index) => (
            <div
              key={index}
              className={"p-2 cursor-pointer " + tagColor(tag)}
              onClick={() => handleDropdownItemClick(tag)}
            >
              {"#"+tagName(tag)}
            </div>
          ))}
        </div>
      )}

      {/* Search Input */}
      {
      editable &&
      <input
        ref={inputRef}
        type="text"
        inputmode="search"
        className="w-full border border-gray-300 rounded p-1 leading-none text-xs md:text-base"
        placeholder={language === "ru" ? "Выберите тэги или создайте новые..." : "Choose tags or create new..."}
        value={searchQuery}
        onChange={handleInputChange}
        onFocus={handleInputChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && searchQuery.trim() !== '') {
            handleAddTag(searchQuery.trim()); // Add tag on Enter key press
          }
        }}
      />
      }
      
    </div>
  );
};

export default TagsInput;
